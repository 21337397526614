import React, { useEffect, useState } from "react";
import api from "../api";
import { useNavigate } from "react-router-dom";
import CommonLoading from "../loader/common-loading";

const OrderProcessing = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const activationEmail = async () => {
      setLoading(true);
      try {
        const response = await api.get("/payment/success");
        let url = response.data.data;
        navigate(url);
        setLoading(false);
      } catch (err) {
        let url = err?.response?.data?.data;
        if (url) navigate(url);
        console.log("error", err);
        setLoading(false);
        setError(true);
      }
    };
    activationEmail();
  }, []);

  return <div>{loading ? <CommonLoading /> : "Loading.."}</div>;
};

export default OrderProcessing;
