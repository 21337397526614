import React, { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import FallbackLoading from "./loader/fallback-loading";
import CandidateProfile from "./pages/dashboards/candidate/profile";
import { loadUser } from "./redux/actions/user";
import PrivateRoute from "./utils/PrivateRoute";
import { useSocket } from "./utils/SocketContext";
import api from "./api";
import Evaluation from "./pages/dashboards/candidate/evaluation";
import Score from "./pages/dashboards/candidate/score";
import { getTitleFromRoute } from "./utils/docTitle";
import { Helmet } from "react-helmet"
import CommonLoading from "./loader/common-loading";
import { ErrorComponent } from "./utils/Authenticated";
import SupportDetail from "./pages/dashboards/recruiter/supportDetail";
import NotFound from "./pages/not-found";
import PaymentSuccess from "./pages/success-page";
import BillingManagement from "./pages/dashboards/admin/billing-management";
import QuestionBank from "./pages/dashboards/admin/question-bank";
import OrderProcessing from "./pages/order-processing";

const LandingPage = lazy(() => import("./pages/landing"));

// Auth routes:->
const RecruiterSignUp = lazy(() => import("./pages/auth/recruiter-signup"));
const RecruiterLoginPage = lazy(() => import("./pages/auth/recruiter-login"));
const RecruiterActivateEmail = lazy(() =>
  import("./pages/auth/recruiter-activate-email")
);

const CandidateSignUp = lazy(() => import("./pages/auth/candidate-signup"));
const CandidateLoginPage = lazy(() => import("./pages/auth/candidate-login"));
const AdminLoginPage = lazy(() => import("./pages/auth/admin-login"));
const CandidateActivateEmail = lazy(() =>
  import("./pages/auth/candidate-activate-email")
);

const ForgotPassword = lazy(() => import("./pages/auth/forgot-password"));
const ChangePassword = lazy(() => import("./pages/auth/change-password"));
const LinkedIn = lazy(() => import("./pages/auth/linkdedin-redirect"));

const DashboardLayout = lazy(() => import("./components/dashboard-layout"));
const RecruiterDashboard = lazy(() =>
  import("./pages/dashboards/recruiter/dashboard")
);
const CandidateDashboard = lazy(() =>
  import("./pages/dashboards/candidate/dashboard")
);
const RecruiterProfile = lazy(() =>
  import("./pages/dashboards/recruiter/profile")
);
const RecruiterCampaigns = lazy(() =>
  import("./pages/dashboards/recruiter/campaigns")
);
const CandidateCampaigns = lazy(() =>
  import("./pages/dashboards/candidate/campaigns")
);
const AdminCampaigns = lazy(() =>
  import("./pages/dashboards/admin/campaigns")
);
const Users = lazy(() =>
  import("./pages/dashboards/admin/users")
);
const CreateCampaign = lazy(() =>
  import("./sections/recruiter/create-campaign")
); const AdminDashboard = lazy(() =>
  import("./pages/dashboards/admin/dashboard")
);
const Project = lazy(() => import("./pages/dashboards/candidate/project"));
const CandidateResults = lazy(() => import("./pages/dashboards/candidate/results"));
const RecruiterResults = lazy(() => import("./pages/dashboards/recruiter/results"));
const RecruiterSupport = lazy(() => import("./pages/dashboards/recruiter/support"));
const CandidateSupport = lazy(() => import("./pages/dashboards/candidate/support"));
const Billing = lazy(()=> import("./pages/dashboards/recruiter/billing"));
const EvaluationLibrary = lazy(()=> import("./pages/dashboards/admin/evaluation"));
const AdminSupport = lazy(() => import("./pages/dashboards/admin/support"));

function App() {
  const location = useLocation();
  const socket = useSocket();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [error, setError] = useState(null);
console.log(socket)

  const fetchServerStatus = async () => {
    try {
      await api.get(process.env.REACT_APP_BASE_URL);
      console.log("Server status fetched successfully");
    } catch (err) {
      console.log(err);
      setError("Server is down. Please try again later.");
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const userJson = localStorage.getItem("user");
      if (userJson && socket) {
        try {
          const userData = JSON.parse(userJson);
          dispatch(loadUser(userData?.role));
          const userId = userData._id;
          const role = userData?.role;
          socket.emit("addUser", userId, role);
        } catch (e) {
          console.error("Error parsing JSON:", e.message);
          setError(e.message)
        }
      } else {
        await fetchServerStatus();
      }
      setInitialLoading(false);
    };

    fetchData();
  }, [dispatch, socket]);

  if (initialLoading || error) {
    return (
      <div className="flex items-center justify-center h-screen">
        {initialLoading ? <FallbackLoading /> : <ErrorComponent errorMessage={error} />}
      </div>
    );
  }

  const isCandidate = user?.role === "candidate";
  const isRecruiter = user?.role === "recruiter";
  const isAdmin = user?.role === "admin";
  let role = user?.role;

  return (
    <div className="App">
      <Helmet>
        <title>{getTitleFromRoute(location.pathname)}</title>
      </Helmet>
      <Suspense fallback={<FallbackLoading />}>
        <Routes>
          <Route
            path="/"
            element={
              user?.role ? <Navigate to="/dashboard" /> : <LandingPage />
            }
          />
          {/* Candidate Auth related routes  */}
          <Route
            path="/candidate/login"
            element={
              user?.role ? <Navigate to="/dashboard" /> : <CandidateLoginPage />
            }
          />
          <Route path="/admin/login" element={<AdminLoginPage />} />
          <Route path="/candidate/signup" element={<CandidateSignUp />} />
          <Route
            path="/candidate/auth/verify"
            element={<CandidateActivateEmail />}
          />
          {/* Recruiter Auth related routes  */}
          <Route
            path="/recruiter/login"
            element={
              user?.role ? <Navigate to="/dashboard" /> : <RecruiterLoginPage />
            }
          />
          <Route path="/recruiter/signup" element={<RecruiterSignUp />} />
          <Route
            path="/recruiter/auth/verify"
            element={<RecruiterActivateEmail />}
          />
          {/* Common Routes */}
          <Route path="/linkedin" element={<LinkedIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          {/* Dashboard routes */}

          {user && <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardLayout user={user} />
              </PrivateRoute>
            }
          >
            <Route
              path="/dashboard"
              element={
                isCandidate ? (
                  <CandidateDashboard />
                ) :
                  isRecruiter ? <RecruiterDashboard />
                    :
                    (isAdmin && <AdminDashboard />)
              }
            />
            <Route
              path={`/dashboard/${role}/profile`}
              element={
                isCandidate ? (
                  <CandidateProfile />
                ) : (
                  isRecruiter && <RecruiterProfile />
                )
              }
            />
            <Route
              path={`/dashboard/${role}/campaigns`}
              element={
                isCandidate ? (
                  <CandidateCampaigns />
                ) : isRecruiter ? <RecruiterCampaigns />
                  : (isAdmin && <AdminCampaigns />)
              }
            />
            <Route
              path={`/dashboard/admin/user-management`}
              element={<Users />} />
            <Route
              path={`/dashboard/recruiter/create-campaign`}
              element={<CreateCampaign />}
            />
            <Route
              path={`/dashboard/candidate/task/:id/:evaluationId/:count`}
              element={<Project />}
            />
            <Route
              path={`/dashboard/${role}/results`}
              element={
                isCandidate ? <CandidateResults /> : isRecruiter && <RecruiterResults />
              }
            />
            <Route
              path={`/dashboard/${role}/support-system`}
              element={
                isCandidate ? <CandidateSupport /> : isRecruiter ? <RecruiterSupport /> : <AdminSupport />}
            />
            <Route
              path={`/dashboard/${role}/support-system/support`}
              element={
                <SupportDetail />}
            />
            <Route path="/dashboard/recruiter/billing" element={<Billing />} />
            <Route path="/dashboard/admin/payment-management" element={<BillingManagement />} />
            <Route path="/dashboard/admin/evaluation-library" element={<EvaluationLibrary />} />
            <Route path="/dashboard/admin/user-management" element={<Users />} />
            <Route path="/dashboard/admin/question-bank" element={<QuestionBank />} />
          </Route>}

          <Route path="/evaluation/:id" element={<Evaluation />} />
          <Route path="/score" element={<Score />} />
          <Route path="/terms-and-conditions" element={<Score />} />
          <Route path="/order/processing" element={<OrderProcessing />} />
          <Route path="/recruiter/billing/success" element={<PaymentSuccess title={'Payment Successful!'} desc={'Your payment has been processed successfully.'} color={'green.500'} />} />
          <Route path="/recruiter/billing/failed" element={<PaymentSuccess title={'Payment Failed!'} desc={'Your payment has not been processed.'} color={'red.500'} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
