import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useAutoLogout } from "./useAutoLogout";
import { LogoutUserT } from "../redux/actions/user";
import api from "../api";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, isAuthenticated } = useSelector((state) => state.user);

  const logout = async () => {
    try {
      const { data } = await api.post("/user/logout");

      if (data.success) {
        console.log("Logged out successfully:", data.message);
        dispatch(LogoutUserT());
        navigate("/signin");
      } else {
        console.error("Logout failed:", data.message);
      }
    } catch (error) {
      console.error("Logout error:", error.message || error);
    }
  };

  useAutoLogout(logout, 600000); 
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export default PrivateRoute;
