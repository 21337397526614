import socketIO from "socket.io-client";

let socket;

export const getSocket = () => {
  if (!socket) {
    const ENDPOINT = process.env.REACT_APP_SOCKET_URI;
    socket = socketIO("https://patrtest.com/socket", { transports: ["polling"], secure: true });
  }
  return socket;
};
