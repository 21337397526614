import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { Button, Progress, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import "../../../assets/css/evaluation.css";
import api from "../../../api";

const Evaluation = () => {
  const [deg, setDeg] = useState(0);
  const [data, setData] = useState([]);
  const [timeLeft, setTimeLeft] = useState(60);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [qs, setQuestions] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const duration = 60000;
  const animationIdRef = useRef(null);
  const questionIndexRef = useRef(currentQuestionIndex);
  const tabSwitchCountRef = useRef(0);
  const loadSwitchCountRef = useRef(0);

  // Fetch questions with error handling
  const fetchData = async () => {
    try {
      const { data } = await api.get(`/campaign/evaluation/${id}`);
      setQuestions(data.data);
      setData(data.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const moveToNextQuestion = useCallback(async () => {
    if (currentQuestionIndex < qs.length - 1) {
      setCurrentQuestionIndex((prevIndex) => {
        questionIndexRef.current = prevIndex + 1;
        return prevIndex + 1;
      });
      setTimeLeft(60);
    } else {
      try {
        const result = await api.put(`/campaign/candidate-evaluation/${id}`, {
          questions: data,
        });
        sessionStorage.removeItem("savedCurrent");
        sessionStorage.removeItem("currentQuestionIndex"); // Clear session storage
        navigate(`/score?score=${result.data.score}&total=${qs.length}`);
      } catch (error) {
        console.error("Error submitting evaluation", error);
      }
    }
  }, [currentQuestionIndex, qs.length, navigate, data, id]);

  // Timer logic
  const startTimer = useCallback(() => {
    const startTime = Date.now();

    const updateTimer = () => {
      if (qs.length !== 0) {
        const elapsedTime = Date.now() - startTime;
        const newDeg = (elapsedTime / duration) * 360;
        const newTimeLeft = Math.max(60 - Math.floor(elapsedTime / 1000), 0);

        setDeg(newDeg);
        setTimeLeft(newTimeLeft);

        if (elapsedTime >= duration) {
          moveToNextQuestion();
        } else {
          animationIdRef.current = requestAnimationFrame(updateTimer);
        }
      }
    };

    if (animationIdRef.current) {
      cancelAnimationFrame(animationIdRef.current);
    }

    animationIdRef.current = requestAnimationFrame(updateTimer);
  }, [qs.length, moveToNextQuestion]);

  useEffect(() => {
    startTimer();

    return () => {
      if (animationIdRef.current) {
        cancelAnimationFrame(animationIdRef.current);
      }
    };
  }, [startTimer, currentQuestionIndex]);

  // Handle tab change restriction with one warning
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.hidden) {
        tabSwitchCountRef.current += 1;

        if (tabSwitchCountRef.current > 1) {
          alert("You have changed the tab again. The evaluation will end.");
          const result = await api.put(`/campaign/candidate-evaluation/${id}`, {
            questions: data,
          });
          sessionStorage.removeItem("savedCurrent");
          sessionStorage.removeItem("currentQuestionIndex"); // Clear session storage
          navigate(`/score?score=${result.data.score}&total=${qs.length}`);
        } else {
          alert("Warning: Changing tabs again will end the evaluation.");
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [navigate, qs.length, id, data]);

  // Handle network disconnection and session storage
  // useEffect(() => {
  //   const savedQuestionIndex = sessionStorage.getItem("currentQuestionIndex");
  //   const savedCurrent = sessionStorage.getItem("savedCurrent");
  
  //   if (savedQuestionIndex) {
  //     const index = parseInt(savedQuestionIndex, 10);
  //     if (!isNaN(index)) {
  //       setCurrentQuestionIndex(index);
  //       questionIndexRef.current = index;
  //     }
  //   }
  
  //   const checkTabRefreshCount = async () => {
  //     const refreshCount = parseInt(savedCurrent, 10);
  
  //     if (!isNaN(refreshCount) && refreshCount > 1) {
  //       alert("You have refreshed the tab again. The evaluation will end.");
  //       try {
  //         const result = await api.put(`/campaign/candidate-evaluation/${id}`, {
  //           questions: data,
  //         });
  //         sessionStorage.removeItem("currentQuestionIndex");
  //         sessionStorage.removeItem("savedCurrent");
  //         navigate(`/score?score=${result.data.score}&total=${qs.length}`);
  //       } catch (error) {
  //         console.error("Error ending evaluation:", error);
  //       }
  //     }
  //   };
  
  //   checkTabRefreshCount();
  
  //   const handleBeforeUnload = () => {
  //     const refreshCount = parseInt(savedCurrent, 10) || 0;
  //     sessionStorage.setItem("currentQuestionIndex", questionIndexRef.current);
  //     sessionStorage.setItem("savedCurrent", (refreshCount + 1).toString());
  
  //     if (refreshCount >= 1) {
  //       alert("Warning: Refreshing it again will end the evaluation.");
  //     }
  //   };
  
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [ qs.length, navigate]);
  

  const currentQuestion = qs[currentQuestionIndex];

  const handleNextClick = () => {
    moveToNextQuestion();
    startTimer();
  };

  const handleRadio = (val, q) => {
    setData((prevQuestions) =>
      prevQuestions.map((el) => (el._id === q ? { ...el, answer: val } : el))
    );
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex justify-between items-center bg-[#4120C8] py-5 px-6">
        <div className="flex-1">
          <span className="px-3 py-2 bg-[#22A7F1] rounded-lg text-white text-sm font-semibold leading-5">
            {currentQuestionIndex + 1}/{qs.length}
          </span>
        </div>
        <div className="flex-1 flex justify-center">
          <Link to="/dashboard/candidate/campaigns">
            <img
              src="/images/landing-page/logo.png"
              alt="PATR"
              className="block h-10"
            />
          </Link>
        </div>
        <div className="flex-1 text-right">
          <Link to="/dashboard/candidate/campaigns">
            <span className="text-xl text-white inline-flex">
              <IoCloseSharp />
            </span>
          </Link>
        </div>
      </div>

      <section className="quest_section flex-auto">
        <Progress
          value={((currentQuestionIndex + 1) / qs.length) * 100}
          size="xs"
          background={"#290F94"}
          sx={{
            "& > div": {
              backgroundColor: "#22A7F1",
              borderRadius: "30px",
            },
          }}
        />
        <div className="evalution_form-wrapper">
          <div className="flex items-center justify-end gap-4 w-full">
            <p className="text-base text-white">Time Remaining ({timeLeft}s)</p>
            <div
              className="timer_pie"
              style={{
                backgroundImage: `conic-gradient(#4484FF ${deg}deg, white 0 0deg)`,
              }}
            />
          </div>

          {currentQuestion && (
            <form className="evalution_form">
              <div key={currentQuestion._id}>
                <h3 className="text-3xl font-bold leading-10 mb-14">
                  {currentQuestion.question}
                </h3>
                <RadioGroup className="radio_wrapper">
                  <Stack gap={"16px"}>
                    {Object.entries(currentQuestion.options).map(
                      ([key, value]) => (
                        <Radio
                          key={key}
                          colorScheme="blue"
                          value={key}
                          onChange={(e) =>
                            handleRadio(e.target.value, currentQuestion?._id)
                          }
                        >
                          <span className="radio_option">{key}</span>
                          <span className="flex-auto">{value}</span>
                        </Radio>
                      )
                    )}
                  </Stack>
                </RadioGroup>
              </div>

              <Button
                onClick={handleNextClick}
                variant={"solid"}
                bg={"#351E99"}
                color={"#fff"}
                _hover={{
                  bg: "#351E99ab",
                }}
                className="w-44 !h-12 p-3 font-semibold mt-14"
              >
                {currentQuestionIndex < qs.length - 1 ? "Next" : "Submit"}
              </Button>
            </form>
          )}
        </div>
      </section>
    </div>
  );
};

export default Evaluation;
