import { useEffect, useRef } from "react";

export const useAutoLogout = (logoutFunction, timeout = 600000) => {
  const logoutTimerRef = useRef(null);
  const lastActivityRef = useRef(Date.now());

  useEffect(() => {
    const resetTimer = () => {
      const now = Date.now();
      const timeSinceLastActivity = now - lastActivityRef.current;

      if (timeSinceLastActivity > 50) { // Debounce delay of 50ms
        lastActivityRef.current = now;

        const timeLeft = timeout - (now - lastActivityRef.current);
        console.log(`[useAutoLogout] Timer reset. Time left: ${Math.max(0, timeLeft / 1000)} seconds`);

        clearTimeout(logoutTimerRef.current);
        logoutTimerRef.current = setTimeout(() => {
          console.log("[useAutoLogout] Timer expired. Logging out...");
          logoutFunction();
        }, timeout);
      }
    };

    const activityEvents = ["click", "keydown", "scroll", "mousemove", "touchstart"];
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer, { passive: true })
    );

    // Initialize timer on mount
    resetTimer();

    // const interval = setInterval(() => {
    //   const timeElapsed = Date.now() - lastActivityRef.current;
    //   const timeLeft = timeout - timeElapsed;
    //   console.log(`[useAutoLogout] Time left until logout: ${Math.max(0, timeLeft / 1000)} seconds`);
    // }, 1000);

    return () => {
      clearTimeout(logoutTimerRef.current);
    //   clearInterval(interval);
      activityEvents.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [logoutFunction, timeout]);
};
